<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Product</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Product</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="overlay" v-if="is_loading">
        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="overlay" v-if="is_loading">
              <i class="fas fa-2x fa-sync-alt fa-spin"></i>
            </div>
            <div class="card-header">
              <h3 class="card-title">Detail</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{name: 'ProductList'}">
                  <i class="fas fa-list"></i> List
                </router-link>
              </div>
            </div>
            <div class="card-body table-responsive p-0">
              <table class="table table-striped">
                <tbody>
                
                  <tr>
                    <td><b>ID</b></td>
                    <td>{{ value.id }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Name</b></td>
                    <td>{{ value.name }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Category</b></td>
                    <td>{{ value.categoryId }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Type</b></td>
                    <td>{{ value.type }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Description</b></td>
                    <td>{{ value.description }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Status</b></td>
                    <td>{{ value.status }}</td>
                  </tr>
                  
                  <tr>
                    <td><b>Application-id</b></td>
                    <td>{{ value.appId }}</td>
                  </tr>
                  
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
      
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Product_property</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                <tr>
                  
                  <th>ID</th>
                  <th>Type</th>
                  <th>Quantitye</th>
                  <th>Status</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(val, index) in value.product_property">
                  
                  <td>{{ val.id }}</td>
                  <td>{{ val.type }}</td>
                  <td>{{ val.quantity }}</td>
                  <td>{{ val.status }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
      </div>
        
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Product_association</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                <tr>
                  
                  <th>ID</th>
                  <th>name</th>
                  <th>value</th>
                  <th>Status</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(val, index) in value.product_association">
                  
                  <td>{{ val.id }}</td>
                  <td>{{ val.name }}</td>
                  <td>{{ val.value }}</td>
                  <td>{{ val.status }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
      </div>
        
    </section>
  </div>
</template>

<script>
import MasterLayout from "@/components/layouts/MasterLayoutComponent.vue";
import {DepartmentsAPI} from "@/services/api";
import axios from "axios";


export default {
  name: "ProductShow",
  data: () => ({
    value: {
      product_property: [],product_association: [],
    },
    pre: {
      
    },
    is_loading: false
  }),
  mounted: function () {
    this.initialize();
  },
  methods: {
    
    async initialize() {
      this.is_loading = true;
      this.params = this.$route.params;

      

      await this.getDetail(this.params.id).then(detail => {
        this.value = detail;
      });

      this.is_loading = false;
    },
    getDetail: function (id) {
      let _this = this;
      return new Promise(resolve => {
        let api_url = "/products/" + id;
        _this.is_loading = true;
        axios.get(api_url)
          .then(response => {
            _this.value = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            console.log("error " + error);
            resolve(error);
          })
          .finally(() => {
            _this.is_loading = false;
          });
      });
    },
  },
  created() {
    this.$emit("update:layout", MasterLayout);
  },
}
</script>
        